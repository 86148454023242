//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ResponsiveImageComponent",
  props: {
    imgobject: {
      type: [Object, String],
      default: null,
    },
    imgobjectWebp: {
      type: Object,
      default: null,
    },
    sizes: {
      type: String,
      default: null,
    },
    asrc: {
      type: String,
      default: "",
    },
    asrcset: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    classes: {
      type: String,
      default: "",
    },
    styles: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 640,
    },
    height: {
      type: Number,
      default: 360,
    },
  },
  computed: {
    imgSrc() {
      if (typeof this.asrc === "string" && this.asrc !== "") {
        return this.asrc;
      }
      if (this.imgobject == null) {
        return "";
      }
      if (typeof this.imgobject === "string") {
        return this.imgobject;
      }
      if (this.imgobject.src) {
        return this.imgobject.src;
      }
      if (this.imgobject.url) {
        return this.imgobject.url;
      }
      return "";
    },
    imageSrcset() {
      if (typeof this.asrcset === "string" && this.asrcset !== "") {
        return this.asrcset;
      }
      if (this.imgobjectWebp == null) {
        return "";
      }
      if (typeof this.imgobjectWebp === "string") {
        return this.imgobjectWebp;
      }
      if (this.imgobjectWebp.srcSet) {
        return this.imgobjectWebp.srcSet;
      }
      const srcset =
        `${this.imgobject["576"].url} ${this.imgobject["576"].dimensions.width}w, ` +
        `${this.imgobject["768"].url} ${this.imgobject["768"].dimensions.width}w, ` +
        `${this.imgobject["992"].url} ${this.imgobject["992"].dimensions.width}w, ` +
        `${this.imgobject.url} ${this.imgobject.dimensions.width}w, `;

      return srcset;
    },
  },
  methods: {
    getClasses() {
      return this.classes + " w-full";
    },
  },
};
