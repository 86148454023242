




























































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component } from "nuxt-property-decorator";
import { parseEmoji } from "~/shared";
import ResponsiveImg from "~/components/core/ResponsiveImg.vue";
import { paymentModuleActive } from "~/config";
import PricingCard from "~/components/cards/PricingCard.vue";

@Component({
  name: "LandingPage",
  components: { ResponsiveImg, PricingCard },
  middleware: ["refreshToken", "notAuthenticated"],
})
export default class LandingPage extends Vue {
  get paymentModuleActive() {
    return paymentModuleActive;
  }

  parseEmoji(text: string) {
    return parseEmoji(text, this);
  }
}
