























































































import Vue from "vue";
import { Component, Prop } from "nuxt-property-decorator";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
  name: "PricingCard",
})
export default class PricingCard extends Vue {
  get faInfoCircle() {
    return faInfoCircle;
  }

  @Prop({
    type: String,
    default: null,
  })
  plan!: string;

  @Prop({
    type: String,
    default: null,
  })
  runtime!: string;

  @Prop({
    type: String,
    default: null,
  })
  priceShared!: string;

  @Prop({
    type: String,
    default: null,
  })
  price!: string;

  @Prop({
    type: String,
    default: null,
  })
  explanation!: string;

  @Prop({
    type: Array,
    default: () => [],
  })
  includedFeatures!: string[];

  @Prop({
    type: Boolean,
    default: false,
  })
  primary!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  showButton!: boolean;

  @Prop({
    type: String,
    default: null,
  })
  buttonText!: string;

  @Prop({
    type: String,
    default: null,
  })
  buttonLink!: string;

  get isLinkHash() {
    return this.buttonLink && this.buttonLink.includes("#");
  }
}
